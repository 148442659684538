import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { Paragraph } from "../Paragraph";
import { Text, Emphasize } from ".";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "text"
    }}>{`Text`}</h1>
    <h2 {...{
      "id": "text-default"
    }}>{`Text (Default)`}</h2>
    <p>{`The Text is used for marking up text. It inherites the style from it's parent, but can be overridden, say to change the `}<inlineCode parentName="p">{`color`}</inlineCode>{`, `}<inlineCode parentName="p">{`fontWeight`}</inlineCode>{`,...`}</p>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={Text} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <p>{`If you need more options, consider using `}<a parentName="p" {...{
        "href": "components/Paragraph"
      }}>{`Paragraph`}</a>{` or `}<a parentName="p" {...{
        "href": "components/Heading"
      }}>{`Heading`}</a>{` instead.`}</p>
    <Playground __position={1} __code={'<Paragraph>\n  Lorem ipsum dolor amet{\' \'}\n  <Text color=\"primary.dark\" fontWeight=\"bold\">\n    photo booth\n  </Text>{\' \'}\n  scenester cornhole trust fund vaporware williamsburg. Selfies tbh tumeric\n  XOXO man braid cred. Skateboard heirloom locavore, kogi everyday carry af\n  tattooed art party asymmetrical cardigan sustainable. Tbh cornhole\n  post-ironic, literally hashtag ethical adaptogen brooklyn bushwick\n  distillery. Vape stumptown swag glossier small batch gastropub. Taxidermy\n  90\'s everyday carry kombucha. Banjo VHS occupy marfa roof party slow-carb.\n</Paragraph>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Paragraph,
      Text,
      Emphasize,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Paragraph mdxType="Paragraph">
    Lorem ipsum dolor amet{' '}
    <Text color="primary.dark" fontWeight="bold" mdxType="Text">
      photo booth
    </Text>{' '}
    scenester cornhole trust fund vaporware williamsburg. Selfies tbh tumeric
    XOXO man braid cred. Skateboard heirloom locavore, kogi everyday carry af
    tattooed art party asymmetrical cardigan sustainable. Tbh cornhole
    post-ironic, literally hashtag ethical adaptogen brooklyn bushwick
    distillery. Vape stumptown swag glossier small batch gastropub. Taxidermy
    90's everyday carry kombucha. Banjo VHS occupy marfa roof party slow-carb.
  </Paragraph>
    </Playground>
    <h2 {...{
      "id": "emphasize"
    }}>{`Emphasize`}</h2>
    <p>{`To allow us to 'emphasize' text. For now the default (and only supported color the `}<inlineCode parentName="p">{`primary.base`}</inlineCode>{`).`}</p>
    <h3 {...{
      "id": "props-1"
    }}>{`Props`}</h3>
    <Props of={Emphasize} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <Playground __position={3} __code={'<Paragraph>\n  Lorem ipsum dolor amet <Emphasize>photo booth</Emphasize> scenester cornhole\n  trust fund vaporware williamsburg.\n  <Emphasize color=\"neutral.base\">Selfies tbh tumeric</Emphasize> XOXO man\n  braid cred. Skateboard heirloom locavore, kogi everyday carry af tattooed\n  art party asymmetrical cardigan sustainable.\n</Paragraph>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Paragraph,
      Text,
      Emphasize,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Paragraph mdxType="Paragraph">
    Lorem ipsum dolor amet{' '}
    <Emphasize mdxType="Emphasize">
      photo booth
    </Emphasize>{' '}
    scenester cornhole trust fund vaporware williamsburg. 
    <Emphasize color="neutral.base" mdxType="Emphasize">
      Selfies tbh tumeric
    </Emphasize>{' '}
    XOXO man braid cred. Skateboard heirloom locavore, kogi everyday carry af
    tattooed art party asymmetrical cardigan sustainable.
  </Paragraph>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      